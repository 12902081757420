* { box-sizing: border-box; }

body {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  overflow: hidden;
}

.window {
  width: 700px;
  height: 400px;
  background-color: rgba(37,37,37,.85);
  border: 1px solid #c9c9c9;
  box-shadow: 4px 4px 0 rgba(0,0,0,.2);
  transition: all ease-in-out .3s;
}

.window-header {
  background-color: #eee;
  height: 25px;
}

.window-controls {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.window-controls .control-item {
  width: 25px;
  height: 25px;
  text-align: center;
  color: #333;
  transition: all ease-in .15s;
  cursor: pointer;
}
.window-controls .control-item:hover { background-color: #ccc; }

.control-item.control-close {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

.control-item.control-close:hover {
  background-color: #ec6060;
  color: #fff;
}


.window-cursor {
  display:grid;
  margin: 5px;
}

.window-cursor .i-path {
  display: flex;
  margin-bottom: 7px;
}

.window-cursor .i-path-indicator1 {
  color: #ce110d;
  font-size: 1.1em;
  font-family: 'Consolas', monospace;
  margin-left: 7px;
}

.window-cursor .i-path-indicator2 {
  color: #fff;
  font-size: 1.1em;
  font-family: 'Consolas', monospace;
  margin-left: 2px;
}

.window-cursor .i-path-indicator3 {
  color: #267ffc;
  font-size: 1.1em;
  font-family: 'Consolas', monospace;
  margin-left: 2px;
}

.window-cursor .i-path-indicator4 {
  color: #fff;
  font-size: 1.1em;
  font-family: 'Consolas', monospace;
  margin-left: 2px;
}

.window-input {
  margin-left: 10px;
  margin-right: 4px;
  font-size: 1.1em;
  color: #fff;
  font-family: 'Consolas', monospace;
}

.window-input a {
  color: #7cb4fc;
  transition: all 0.3s;
}

.window-input a:hover {
  color: #ffbe55;
}

.window-cursor .i-cursor-underscore {
  width: 10px;
  height: 3px;
  background-color: #fff;
  align-self: flex-end;
  margin-right: 5px;
  animation: blink 1s steps(2, start) infinite;
}


@keyframes blink {
  to { visibility: hidden; }
}

@media screen and (max-width: 1366px) {
  .window {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 700px) {
  .window {
    width: 580px;
    height: 400px;
  }
}

@media screen and (max-width: 580px) {
  .window {
    width: 500px;
    height: 350px;
  }

  .window-cursor .i-path {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 450px) {
  .window {
    width: 350px;
    height: 380px;
  }

  .window-cursor .i-path {
    font-size: 0.8em;
  }
}