@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');


.skills h1 {
  color:#7cb4fc;
  /* text-shadow:#89d3fd 0 -1px 35px; */
  font-family: Poppins, sans-serif;
}

.skills h3 {
  color:#7cb4fc;
  font-family: Poppins, sans-serif;
}
.skills p {
  color:#7cb4fc;
  font-family: Poppins, sans-serif;
}

@media screen and (max-width: 770px) {
  .skills-content img {
    width: 45px;
    height: 45px;
  }

  .skills {
    margin-top: 5rem;
  }

  .skills-body {
    display: flex;
    justify-content: center;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

@media screen and (max-width: 470px) {
  .skills-content img {
    width: 40px;
    height: 40px;
  }
  .skills-content p {
    font-size: 0.9rem;
  }
  .skills-body {
    margin-left: 25px;
    margin-right: 15px;
  }
  
}