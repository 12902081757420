footer {
  /* background-color: #2c323f; */
  margin: 100px 0 20px 0;
}
.copyright {
  color: #7cb4fc;
  text-align: center;
  font: 500 18px Poppins, sans-serif;
}

.copyright a {
  color: #ffbe55;
}