.projects-container h1 {
  color: #7cb4fc;
  /* text-shadow:#89d3fd 0 -1px 35px; */
  font-family: Poppins, sans-serif;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 2rem;
}

.projects-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.projects-bodyContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4.5rem;
  width: 100%;
}

.projects-viewAll {
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.projects-viewAll a button {
  outline: none;
  border: none;
  width: 150px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: inherit;
  border-radius: 45px;
  font-size: 1.05rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  padding-left: 1.5rem;
  cursor: pointer;
}

.viewAllBtn {
  color: #7cb4fc;
  background-color: #213149;
  transition: color 0.2s;
}

.viewAllBtn:hover {
  color: #ffbe55;
}
.viewArr {
  color: #7cb4fc;
  background-color: #445268;
  width: 40px;
  height: 40px;
  padding: 0.5rem;
  font-size: 1.05rem;
  border-radius: 50%;
  cursor: pointer;
  transition: color 0.2s;
}

.viewArr:hover {
  color: #ffbe55;
  background-color: #5d81b4;
}

/* Project Card */

.projectCard {
  /* box-shadow: 4px 4px 8px rgba(255, 255, 255, 0.2); */
  width: 404px;
  height: 460px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.4rem 1rem;
  position: relative;
  overflow: hidden;
}

.projectContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.iconBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 2px solid #5c84be;
  color: #5c84be;
  transition: all 0.2s;
}

.iconBtn:hover {
  background-color: #5d81b4;
  color: #b3c9e7;
  transform: scale(1.1);
  border: 2px solid;
}

.icon {
  font-size: 1.1rem;
  transition: all 0.2s;
}

.projectContent > h2 {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.525rem;
  line-height: 110%;
  text-align: center;
}

.projectContent > img {
  width: 100%;
  height: 45%;
  transition: opacity 0.3s 0.3s;
  object-fit: cover;
}

.projectCard:hover img {
  opacity: 0;
}

.project-showcaseBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.project-showcaseBtn > a {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.5s 0.3s;
}

.projectCard:hover .project-showcaseBtn a:nth-child(2) {
  transform: translateX(-140px) scale(1.1) !important;
}

.projectCard .project-desc {
  position: absolute;
  width: 95%;
  min-height: 180px;
  max-height: 225px;
  left: 0;
  top: 0;
  transform: translate(-110%, 40%);
  padding: 15px;
  border-radius: 0 20px 20px 0;
  transition: transform 0.9s;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  background-color: #3a5070;
}

.projectCard:hover .project-desc {
  transform: translate(-2%, 40%);
}

.projectCard .project-lang {
  position: absolute;
  bottom: 20px;
  max-height: 120px;
  right: 0;
  width: 140px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
  transform: translateX(100%);
  transition: transform 0.5s 0.3s;
  padding: 0.825rem;
}

.projectCard:hover .project-lang {
  transform: translateX(0);
}

.project-lang > span {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  word-break: break-word;
  line-height: 100%;
}

@media screen and (max-width: 1366px) {
  .projectCard {
    width: 110%;
  }
}

@media screen and (max-width: 1090px) {
  .projectCard {
    width: 100%;
  }
  .projects-bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .projects-header h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 600px) {
  .projects-header h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  .projects-viewAll {
    position: relative;
    margin-top: 4rem;
  }
  .projects-viewAll a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 400px) {
  .project-desc p {
    font-size: 0.8rem;
    line-height: 110%;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
