.education h1 {
  color: #7cb4fc;
  /* text-shadow:#89d3fd 0 -1px 35px; */
  font-family: Poppins, sans-serif;
}

.education-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* color: hsla(117, 100%, 79%, 0.702); */
}

.education-description {
  width: 100%;
  /* flex: 0.65; */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* margin-left: 7%; */
}

.education-description > h1 {
  font-size: 3.5rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 2rem;
}

.education-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #162030;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  transition: background-color 200ms ease-in-out;
}

.education-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  background: #7cb4fc;
  transition: 0.3s;
  transform: scale(0.9);
  z-index: -1;
}

.education-card:hover::before {
  transform: scale(1);
  box-shadow: 0 0 15px #7cb4fc;
}

.educard-img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.educard-img > img {
  width: 40px;
}

.education-details {
  margin-left: 0.6rem;
}

.education-details > h6 {
  font-family: Poppins, sans-serif;
  font-size: 0.85rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.education-details > h4 {
  font-family: Poppins, sans-serif;
  font-size: 1.225rem;
  font-weight: 600;
}

.education-details > h5 {
  font-family: Poppins, sans-serif;
  font-size: 1.15rem;
  font-weight: 600;
}

.education-image {
  box-sizing: border-box;
  padding: 1rem;
  flex: 0.35;
  /* margin-left: 1rem; */
  pointer-events: none;
}

.education-image > img {
  width: 600px;
}

@media screen and (max-width: 992px) {
  .education {
    min-height: 100%;
  }
  .education-image {
    display: none;
  }
  .education-description {
    flex: 1;
    margin: auto;
  }
  .education-description > h1 {
    font-size: 3.2rem;
  }
  .education-card {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .education-description > h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
    align-self: center;
  }
  .education-description {
    padding: 1rem;
  }
  .education-card {
    padding: 1rem;
    margin-bottom: 1.5rem;
    height: 130px;
  }
  .education-card::before {
    height: 130px;
  }
  .educard-img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .educard-img > img {
    width: 30px;
  }
  .education-details > h6 {
    font-size: 0.85rem;
    margin-bottom: 0.45rem;
  }

  .education-details > h4 {
    font-size: 1.125rem;
    line-height: 126%;
  }

  .education-details > h5 {
    font-size: 1.05rem;
    line-height: 126%;
  }
}

@media screen and (max-width: 460px) {
  .education-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 200px;
    margin-left: 10%;
  }
  .education-card::before {
    width: 80%;
    height: 200px;
    margin-left: 10%;
  }
}
