.contact h1 {
  color:#7cb4fc;
  /* text-shadow:#89d3fd 0 -1px 35px; */
  font-family: Poppins, sans-serif;
}

.contact-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366px) {
  .contact-content {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}