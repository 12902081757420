 form {
   width: 500px;
   height: 500px;
   margin-left: 10rem;
 }

 form .label {
   color: #7cb4fc;
 }

 form .button {
   color: #000;
   background-color: #7cb4fc;
   border: none;
   font: 700 24px Poppins, sans-serif;
   transition: all 0.4s;
 }

 form .button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background: #7cb4fc;
  transition: .4s;
  transform: scale(.9);
  z-index: -1;
}

form .button:hover::before {
  transform: scale(1);
  box-shadow: 0 0 15px #7cb4fc;
}

form .input, .textarea {
  font-family: Poppins, sans-serif;
  color: white;
  background-color: #162030;
}

form .textarea::placeholder {
  font-family: Poppins, sans-serif;
  color: rgb(99, 99, 99);
}

@media screen and (max-width: 1366px) {
  form {
    margin-left: 103px;
  }
}

@media screen and (max-width: 700px) {
  form {
    margin-left: 50px;
  }
}

@media screen and (max-width: 580px) {
  form {
    margin-left: 0px;
  }
}

@media screen and (max-width: 450px) {
  form {
    width: 350px;
    /* height: 300px; */
  }
}